export default function () {

    document.querySelectorAll("[data-mapo-seo-init]").forEach(function (el) {
        const elEventAttribute = el.getAttribute("data-mapo-seo-event");
        if (elEventAttribute == "DOMContentLoaded" || elEventAttribute == "load" || elEventAttribute == "scrollend" || elEventAttribute == "resize") {
            addEventListener(elEventAttribute, (e) => {
                if (!window.dataLayer){
                    return;
                }
                console.log(checkView(el))
                window.dataLayer.push(checkView(el));
            }, false);
        } else {
            el.addEventListener(elEventAttribute, (e) => {
                if (!window.dataLayer){
                    return;
                }
                console.log(generateObjectToSend(el))
                window.dataLayer.push(generateObjectToSend(el));
            });
        }
    });
    window.addEventListener('DataLayerEventPush', (e) => {
        if (!window.dataLayer){
            return;
        }
        window.dataLayer.push(e.detail.data);
    })
}


const generateObjectToSend = (el) => {
    const allAttributes = getAllAttributes(el);
    let objectToSend = {};
    objectToSend['event'] = 'ga4_event';
    Object.entries(allAttributes).forEach(([key, value]) => {
        if (key.startsWith('data-seo-')) {
            const nameProperty = key.substring('data-seo-'.length);
            objectToSend[nameProperty] = value;
        }
    });
    return objectToSend;
}
const getAllAttributes = el => el.getAttributeNames().reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
}), {});
const checkView = el => elementInViewport(el) ? generateObjectToSend(el) : null;
const elementInViewport = (el) => {
    let rect = el.getBoundingClientRect();
    return (
        rect.bottom > 0 && rect.right > 0 && rect.left <
        (window.innerWidth || document.documentElement.clientWidth) && rect.top < (window.innerHeight || document.documentElement.clientHeight)
    );
}
