import jQuery from "jquery";
import { sha256 } from 'js-sha256';
var $ = jQuery;
export default function auth() {
    jQuery.ajax({
        type: 'GET',
        url: isLoggedUser,
        data: {},
        success(result) {
            $('#btn-login-header').addClass('d-none');
            $('#btn-user-header').removeClass('d-none');
            if (!($("#btn-user-header #span-user-name:contains(" + result.user.name + ")").length)) {
                $('#btn-user-header #span-user-name').append(result.user.name);
                $('.login-menu-name').append(" " + result.user.name);
                let isPro = false;
                let isPrivate = false
                result.user.roles.forEach(item => {
                    if(result.user.email_verified_at && item.name  === 'Trade' || result.user.email_verified_at && item.name === 'Professional') {
                        isPro = true;
                    }
                    if(result.user.email_verified_at && item.name === 'Private') {
                       isPrivate = true;
                    }
                })

                if(isPro) {
                    $('.menu-professional').removeClass('d-none');
                    $('.menu-private-user').addClass('d-none');
                    $('.menu-validate-user').addClass('d-none');
                }
                if(!isPro && isPrivate) {
                    $('.menu-professional').addClass('d-none');
                    $('.menu-private-user').removeClass('d-none');
                    $('.menu-validate-user').addClass('d-none');
                }

            }
            $('.open-login').on('click', function() {
                $('#btn-user-header').click();
            });

            const event = new CustomEvent('UserLoggedIn', {
                detail: {
                    data: result.user,
                },
            });
            window.dispatchEvent(event);

            let data = {};
            data.login_status = 'logged';
            data.user_id = result.user.id;
            data.m_shx = result.user.email ? sha256(result.user.email) : null;
            data.p_shx = result.user.data.telephone ? sha256(result.user.data.telephone) : null;
            data.first_name_shx = result.user.data.name ? sha256(result.user.data.name) : null;
            data.last_name_shx = result.user.data.surname ? sha256(result.user.data.surname) : null;
            data.street_shx = result.user.data.address ? sha256(result.user.data.address) : null;
            data.city = result.user.data.city;
            data.postal_code = result.user.data.cap;
            data.country = result.user.data.country;
            data.language = result.user.data.lang;
            data.new_customer = false;
            data.user_type = result.user.data.user_type;
            data.account_creation_date = result.user.created_at;
            data.page_type = location.href.substring(location.href.lastIndexOf('/') + 1);
            const event2 = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event2);
        },
        error() {
            $('#btn-login-header').removeClass('d-none');
            $('#btn-user-header').addClass('d-none');

            $('.open-login').on('click', function() {
                $('#btn-login-header').click();
            });
            if (/[?&]login=open/.test(location.search)) {
                $('#btn-login-header').click();
            }

            let data = {};
            data.login_status = 'not logged';
            data.page_type = location.href.substring(location.href.lastIndexOf('/') + 1);
            const event = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event);
        }
    });
}
