export default function downloads() {
    document.addEventListener('livewire:init', () => {

        Livewire.on('downloadEventAnalytics', (params) => {
            let data = params[0];
            data['event'] = 'ga4_event';
            data['event_name'] = 'download';
            const event = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event);
        });

        Livewire.on('selectedFamily-scroll-down', (event) => {
            console.log('dispatch event')
            location.href = "#downloadAccordionPlaceHolder";
        });

    });
}
