export default function reservedAreaVideo() {
    document.addEventListener('livewire:init', () => {
        Livewire.on('$refresh', (event) => {
            const iframeVideoModals = document.querySelector('#reservedAreaVideos').querySelectorAll('.iframe-video-modal');
            if (iframeVideoModals.length) {
                iframeVideoModals.forEach(iframeVideoModal => {
                    iframeVideoModal.addEventListener('shown.bs.modal', (e) => {
                        const iframe = e.currentTarget.querySelector('iframe');
                        if (
                            iframe
                            && !iframe.hasAttribute('src')
                            && iframe.getAttribute('data-src')
                        ) {
                            // Reload iframe
                            const dataSrc = iframe.getAttribute('data-src');
                            iframe.setAttribute('src', dataSrc);
                            // Play iframe
                            setTimeout(() => {
                                let player = new Vimeo.Player(iframe);
                                player.play();
                            }, 600)
                        }
                    });
                    iframeVideoModal.addEventListener('hidden.bs.modal', (e) => {
                        const iframe = e.currentTarget.querySelector('iframe');
                        // Stop iframe playing
                        iframe.removeAttribute('src');
                    });
                })
            }
        });
    });
}
