import jQuery from "jquery";

export default function () {
    (function ($) {
        $('#selectCountry_2').on('change', function() {
            $.ajax({
                url: provincesUrl + "?country_code=" + $(this).val(),
                success: function (data) {
                    if (data) {
                        $('#selectProvince').attr('required', true).parent().removeClass('d-none');
                        $('#selectCity').attr('required', true).parent().removeClass('d-none');
                        $('#selectProvinceManual').removeAttr('required').parent().addClass('d-none');
                        $('#selectCityManual').removeAttr('required').parent().addClass('d-none');
                        $('#selectProvince').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        $('#selectCity').html("<option value=''>" + chooseOneTrans + "</option>");

                        let oldValue = $('#selectProvince').data("value");
                        $('#selectProvince').val(oldValue).trigger("change");
                    } else {
                        $('#selectProvince').removeAttr('required').parent().addClass('d-none');
                        $('#selectCity').removeAttr('required').parent().addClass('d-none');
                        $('#selectProvinceManual').attr('required', true).parent().removeClass('d-none');
                        $('#selectCityManual').attr('required', true).parent().removeClass('d-none');
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectCountry_2').trigger('change');

        $('#selectCountry_3').on('change', function() {
            $.ajax({
                url: provincesUrl + "?country_code=" + $(this).val(),
                success: function (data) {
                    if (data) {
                        $('#selectProvince_2').attr('required', true).parent().removeClass('d-none');
                        $('#selectProvince_2Manual').removeAttr('required').parent().addClass('d-none');
                        $('#selectProvince_2').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        let oldValue = $('#selectProvince_2').data("value");
                        $('#selectProvince_2').val(oldValue).trigger("change");
                    } else {
                        $('#selectProvince_2').removeAttr('required').parent().addClass('d-none');
                        $('#selectProvince_2Manual').attr('required', true).parent().removeClass('d-none');
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectCountry_3').trigger('change');

        $('#selectProvince').on('change', function() {
            $.ajax({
                url: citiesUrl + "?province_code=" + $(this).val(),
                success: function (data) {
                    if (data != "") {
                        $('#selectCity').attr('required', true).parent().removeClass('d-none');
                        $('#selectCityManual').removeAttr('required').parent().addClass('d-none');
                        $('#selectCity').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        let oldValue = $('#selectCity').data("value");
                        $('#selectCity').val(oldValue);
                    } else {
                        $('#selectCity').removeAttr('required').parent().addClass('d-none');
                        $('#selectCityManual').attr('required', true).parent().removeClass('d-none');
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectProvince').trigger('change');

        //citiesUrl
    })(jQuery);
}
